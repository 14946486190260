<template>
    <div>
        <img class="example-headImg" src="../../assets/image/selected/03-2-3banner.png"/>
        <div class="head-explainBox">
            <div class="head-explainBox-title1">iDAS整合数据分析系统</div>
            <div class="head-explainBox-content1">韩国某知名化妆品集团</div>
            <div class="head-explainBox-line"></div>
            <div class="head-explainBox-title2">项目介绍</div>
            <div class="head-explainBox-content2">客户公司是全球最大的化妆品企业之一，从上世纪90年代起开展全球品牌战略，业务遍布五大洲超过40多个
                国家和地区。伴随自身业务快速发展的同时，需要通过数据与业务的深度融合促进业务创新，实现数据价值，现有的数据仓库架构分散，没有统一的指
                标体系，不能满足业务的需求，面临着诸多挑战。项目针对销售、促销、库存的分析体系，形成较为完善的数据仓库，整合内部、外部、手工数据，帮
                助业务部门整合分析指标，自助分析数据，除此之外，还可自助制作Dashboard，节省制作时间，充分挖掘数据。</div>
        </div>
        <div class="head-list">
            <div class="head-list-item head-list-item-ischeck" style="margin-left:26.3rem" @click="goAssignBlock('block'+ 0,50)"><span class="title">业务痛点</span></div>
            <div class="head-list-item" @click="goAssignBlock('block'+ 1,50)"><span class="title">实施方案</span></div>
            <div class="head-list-item" @click="goAssignBlock('block'+ 2,50)"><span class="title">实施收益</span></div>
        </div>
        <div class="container-1" style="display:flex" ref="block0">
            <div class="purchase-content-left">
                <div class="purchase-title">业务痛点</div>
            </div>
            <div class="purchase-content-right">
                <div class="selected-titlecheck">
                    <div class="selected-contain1">
                        <div class="title1">External</div>
                        <div class="title2" @click="changeout(1)" :style="{'color': changeindex == 1?'#005395' : '#666666'}">外部<div v-if="changeindex == 1" class="line"></div></div>
                        <div class="title1" style="margin-left:3.9rem">Internal</div>
                        <div class="title2" :style="{'color': changeindex == 2?'#005395' : '#666666','margin-left':'15rem'}" @click="changeout(2)">内部<div v-if="changeindex == 2" class="line"></div></div>
                    </div>  
                </div>
                <div v-if="changeindex == 2" class="selected-box">
                    <div class="selected-card">
                        <div class="title1">可靠的外部数据获取困难</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">   多格式数据的清洗整合</div>
                    </div>
                    <div class="selected-card">
                        <div class="title1">缺少完整的分析模型</div>
                    </div>
                </div>
                <div v-else-if="changeindex == 1" class="selected-box" style="height:25rem">
                    <div class="selected-card" style="width: 25rem;height:25rem">
                        <div class="title2">数仓架构分散，数据分散在多个渠道多个品牌的系统中，由不同的业务
                            系统负责不同的业务模块的数据架构设计及实施</div>
                    </div>
                    <div class="selected-card" style="width: 25rem;height:25rem">
                        <div class="title2">数据仓库比较传统，缺失非结构化数据分析手段，跑批性能和报表查询
                            性能不能满足业务的需求</div>
                    </div>
                    <div class="selected-card" style="width: 25rem;height:25rem">
                        <div class="title2">按照报表需求，分别进行统计加工，同一指标在不同报表间存在差异</div>
                    </div>
                    <div class="selected-card" style="width: 25rem;height:25rem">
                        <div class="title2">数据需求由多个系统实现，修改繁琐，交付周期长</div>
                    </div>
                    <div class="selected-card" style="width: 25rem;height:25rem">
                        <div class="title2">数据量越来越大，现有系统缺少弹性</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-2" style="display:flex;height: 60rem;" ref="block1">
            <div class="purchase-content-left">
                <div class="purchase-title">实施方案</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-box">
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-3架构设计.svg"/>
                        <div>
                            <div class="title1">架构设计</div>
                            <div class="title2">根据比孚已有的行业经验与Amazon实施经验，结合其数据情况，为该企业规划了一套循序渐进的、基于
                                Amazon新型数据架构。最终根据企业IT环境实际情况，协助完成物理架构落地</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-3指标整理.svg"/>
                        <div>
                            <div class="title1">指标整理</div>
                            <div class="title2">对数据源进行探查，分析现有指标逻辑，协调各部门，制定一套完整的指标体系</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-3模型设计.svg"/>
                        <div>
                            <div class="title1">模型设计</div>
                            <div class="title2">基于企业业务实质，抽象和设计概念数据模型，明确业务概念和关系。划分清晰的数据存储层次与主要
                                功能，定义数据管理规范</div>
                        </div>
                    </div>
                    <div class="card">
                        <img class="logo" src="../../assets/image/selected/03-2-3报表实施.svg"/>
                        <div>
                            <div class="title1">报表实施</div>
                            <div class="title2">根据不同部门的报表需求，合理制定报表体系。充分利用Amazon Redshift的性能，优化报表查询性能</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-1" style="display:flex;margin-bottom:10rem" ref="block2">
            <div class="purchase-content-left">
                <div class="purchase-title">实施收益</div>
            </div>
            <div class="purchase-content-right">
                <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>数据平台</div>
                <div class="purchase-content" style="margin-left: 2.4rem;">云上数据湖容纳了大量丰富数据，为提供各种数据服务成为了可能；</div>
                <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>指标体系</div>
                <div class="purchase-content" style="margin-left: 2.4rem;">制定一套完整的指标体系满足不同的业务需求，统一部门间的业务认识；</div>
                <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>模型设计</div>
                <div class="purchase-content" style="margin-left: 2.4rem;">企业数据仓库的架构与模型满足未来业务发展变化的需要；</div>
                <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>数据性能</div>
                <div class="purchase-content" style="margin-left: 2.4rem;">1. ETL整体过程整体执行效率较线下的数据仓库提升倍；<br/>
                    2. 报表查询平均时间较之前提升数倍。</div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',
    metaInfo:{
        meta:[
        { name:'keywords', content:'iDAS整合数据分析系统,数据平台,指标体系,模型设计,数据性能' },
        { name:'description', content:'客户公司是全球最大的化妆品企业之一，从上世纪90年代起开展全球品牌战略，业务遍布五大洲超过40多个 国家和地区。伴随自身业务快速发展的同时，需要通过数据与业务的深度融合促进业务创新，实现数据价值，现有的数据仓库架构分散，没有统一的指 标体系，不能满足业务的需求，面临着诸多挑战。项目针对销售、促销、库存的分析体系，形成较为完善的数据仓库，整合内部、外部、手工数据，帮 助业务部门整合分析指标，自助分析数据，除此之外，还可自助制作Dashboard，节省制作时间，充分挖掘数据。' }
        ]
    },
    data() {
        return {
            changeindex:1,
            navOffsetTop: 0,
        };
    },
    mounted() {
        this.activeIndex = '3'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
        // 监听滚动事件
        window.addEventListener('scroll', this.fiexdNav)
        this.getData()
    },
    destroyed () {
        // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
        window.removeEventListener('scroll', this.fiexdNav)
    },
    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
        changeout(value){
            console.log(value)
            this.changeindex = value
        },
        goAssignBlock(el, speed) {
        const navHeight = document.querySelector('.head-list').offsetHeight //导航栏的高度
        let _this = this;
        let windowH = window.innerHeight; //浏览器窗口高度
        let h = this.$refs[el].offsetHeight; //模块内容高度
        let t = this.$refs[el].offsetTop - navHeight - speed; //模块相对于内容顶部的距离
        let top = t ; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
        let scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop; //滚动条距离顶部高度
        let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
        let requestId;
        //采用requestAnimationFrame，平滑动画
        function step() {
            //判断让滚动条向上滚还是向下滚
            if (scrollTop < top) {
                if (currentTop <= top) {
                    //   window.scrollTo(x,y) y为上下滚动位置
                    window.scrollTo(0, currentTop);
                    requestId = window.requestAnimationFrame(step);
                } else {
                    window.cancelAnimationFrame(requestId);
                }
                //向下滚动
                currentTop += speed;
            } else {
                if (top <= currentTop) {
                    //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
                    window.scrollTo(0, currentTop);
                    requestId = window.requestAnimationFrame(step);
                } else {
                    window.cancelAnimationFrame(requestId);
                }
                //向上滚动
                currentTop -= speed;
            }
        }
        window.requestAnimationFrame(step);
        },
        /** 设置导航条nav到达页面顶部时固定 **/
        // 1.获取导航条nav的offsetTop值，存储在data中（注：之所以不放在滚动事件中，是为了以防添加固定样式后offsetTop值为零,导致页面需要滚动到最上面才可以回到原位）
        getData () {
            this.navOffsetTop = document.querySelector('.head-list').offsetTop + 60
            console.log('this.navOffsetTop',this.navOffsetTop)
        },
        fiexdNav () {
            const navHeight = document.querySelector('.head-list').offsetHeight //导航栏的高度
            // 2.获取当前页面的卷曲高度
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            const nav = document.querySelector('.head-list')
            // 3.判断卷曲高度是否大于等于导航条的offsetTop值
            if (scrollTop > this.navOffsetTop) {
                //   3.1若满足，则给nav导航添加固定样式
                nav.classList.add('fixedNav')
            } else {
                //   3.2若不满足，则删除nav导航的固定样式
                nav.classList.remove('fixedNav')
            }

            /** 当滚动到一定区域时给导航项添加选中样式 **/
            //  1.获取所有锚点元素
            // 2.获取锚点元素的offsetTop值，并收集在一个数组
            const contentsOffsetTop = []
            // contents.forEach(item => {
            //     contentsOffsetTop.push(item.offsetTop)
            //     console.log('2222',item.offsetTop)
            // })
            contentsOffsetTop.push(this.$refs['block0'].offsetTop)
            contentsOffsetTop.push(this.$refs['block1'].offsetTop)
            contentsOffsetTop.push(this.$refs['block2'].offsetTop)
            // console.log('contentsOffsetTop',contentsOffsetTop)
            // console.log('contentsOffsetTop',contentsOffsetTop)
            // 3.获取页面高度
            const pageHeight = window.innerHeight
            // 4.获取nav的子元素
            const navChildren = document.querySelectorAll('.head-list .head-list-item')
            for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[0].classList.add('head-list-item-ischeck')
                }
            if(scrollTop>contentsOffsetTop[0] + this.$refs['block0'].offsetHeight/2){
                for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[1].classList.add('head-list-item-ischeck')
                }
            }
            if(scrollTop>contentsOffsetTop[1] + this.$refs['block1'].offsetHeight/2){
                for (let j = 0; j < contentsOffsetTop.length; j++) {
                    navChildren[j].classList.remove('head-list-item-ischeck')
                    navChildren[2].classList.add('head-list-item-ischeck')
                }
            }
        },
        /**
         *设置点击导航跳转到指定选择器对应的锚点元素
        * @param {*} selector
        **/
        skipTo (selector) {
            const navHeight = document.querySelector('.head-list-item').offsetHeight
            // scrollIntoView() js原生方法，实现锚点滚动过渡
            const target = document.querySelector(selector)
            target.scrollIntoView({ behavior: 'smooth' })
            // scrollTo() 把内容滚动到指定的坐标。减去导航高度的目的：导航用定位固定在顶部，如果不减去，导航栏会遮挡一部分内容
            window.scrollTo(0, target.offsetTop - navHeight)
        }
    },
};
</script>

<style lang="less" scoped>
.selected-titlecheck{
    width: 106.8rem;
    display: flex;
    text-align: center;
    // border: 1px solid #f00;
    .selected-contain1{
        display: flex;
        margin: auto;
    }
    .title1{
        width: 8.4rem;
        height: 2rem;
        font-size: 2rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
        opacity: 0.2;

        margin-bottom: 4.8rem;
        margin-top: 2.8rem;
    }
    .title2{
        position: absolute;
        width: 4rem;
        height: 4rem;
        font-size: 2rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #005395;
        line-height: 2.8rem;
        margin: 1.9rem 0 0 3rem;
        cursor: pointer;
        .line{
            width: 4rem;
            height: 2rem;
            border-bottom: 3px solid #005395;
            border-radius: 50%;
            margin: -1rem 0 0 0rem;
        }
    }
}
.purchase-content-left{
    width:33rem;
    text-align: justify;
    margin-left: 26rem;
    margin-top: 5.9rem;

}
.purchase-title{
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Regular';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.purchase-doit{
    color: #005395;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
.purchase-content{
    height: 7.7rem;
    font-size: 1.6rem;
    font-family: 'CN_Regular';
    font-weight: 400;
    color: #666666;
    line-height: 2.6rem;

    margin: 1.3rem 0 0 0.2rem;
}
.purchase-content-right{
    text-align: justify;
    margin-top: 5.9rem;
    padding-bottom: 2rem;
}
.solution-img{
    width: 106.8rem;
    height: 71.7rem;
}
.solution-img2{
    width: 107rem;
    height: 41.7rem;
    margin-top: 5rem;
}
.benefits-img{
    width: 80.5rem;
    height: 33.6rem;
}
.selected-box{
    width: 106.8rem;
    height: 15rem;
    display: flex;
    margin-bottom: 5rem;
    .selected-card{
        width: 35.6rem;
        height: 12rem;
        background: #FFFFFF;
        border: 1px solid #EDEDED;
        .title1{
            text-align: center;
            width: 29.2rem;
            height: 6rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: auto;
            margin-top: 3.5rem;
        }
        .title2{
            text-align: justify;
            width: 15rem;
            height: 15rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: auto;
            margin-top: 3.5rem;
        }
    }
    .selected-card:hover{
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.2);
        border: 1px solid #005395;
    }
}
.purchase-box{
    width: 106rem;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    
    .card{
        width: 52.8rem;
        height: 18rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 3rem;

            margin: 2rem 0 0 2.1rem;
        }
        .title2{
            width: 43.2rem;
            height: 7.2rem;
            font-size: 1.6rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 2.4rem;

            margin: 1.5rem 0 0 2.1rem;
        }
    }
}
</style>
